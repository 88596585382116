import { Observable, Observer, fromEvent } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { Logger } from '../logger';
import { getCanvasBlob } from './blobHelper';
import { IPhotoBlobData } from '../interfaces/photoBlobData.interface';


export function getBase64FromImageUrl(url: string): Observable<string> {
  return new Observable((observer: Observer<string>) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'arraybuffer';
    xhr.open('GET', url);
    xhr.onload = () => {
      let base64;
      let binary;
      let bytes;
      let mediaType;

      bytes = new Uint8Array(xhr.response);
      // NOTE String.fromCharCode.apply(String, ...
      // may cause "Maximum call stack size exceeded"
      binary = [].map.call(bytes, (byte: number) => {
        return String.fromCharCode(byte);
      }).join('');
      mediaType = xhr.getResponseHeader('content-type');
      base64 = [
        'data:',
        mediaType ? mediaType + ';' : '',
        'base64,',
        btoa(binary)
      ].join('');
      observer.next(base64);
      observer.complete();
    };
    xhr.onerror = err => {
      observer.error(err);
      observer.complete();
    };
    xhr.onreadystatechange = () => {
      if (xhr.status === 404) {
        observer.error('Not found!');
        observer.complete();
      }
    };
    xhr.send();
  });
}

export function waitForImageLoaded(url: string): Observable<{ width: number, height: number }> {
  const image = new Image();
  const result = new Observable((observer: Observer<any>) => {
    image.addEventListener('load', evt => {
      observer.next(evt);
      observer.complete();
    });
    image.addEventListener('error', () => {
      console.log('Img err.');
      observer.error('Error occurred when image is loading...');
      observer.complete();
    });
  });
  image.src = url;
  return result;
}

export interface IPhotoOrientation {
  flipVertical: boolean;
  flipHorizontal: boolean;
  angle: number;
}

const orientationExifTagId = 274; // 0x0112

export function getImageOrientation(file: File, logger: Logger): Observable<IPhotoOrientation> {
  return new Observable((observer: Observer<IPhotoOrientation>) => {
    const callback = (imgData: any) => {
      const orientationNumber = imgData.exif && imgData.exif[orientationExifTagId] ? imgData.exif[orientationExifTagId] : 0;
      const orientation: IPhotoOrientation = {
        flipVertical: false,
        flipHorizontal: false,
        angle: 0
      };
      switch (orientationNumber) {
        case 2:
          // horizontal flip
          orientation.flipHorizontal = true;
          break;
        case 3:
          // 180° rotate left
          orientation.angle = 180;
          break;
        case 4:
          // vertical flip
          orientation.flipVertical = true;
          break;
        case 5:
          // vertical flip + 90 rotate right
          orientation.flipVertical = true;
          orientation.angle = 270;
          break;
        case 6:
          // 90° rotate right
          orientation.angle = 270;
          break;
        case 7:
          // horizontal flip + 90 rotate right
          orientation.flipHorizontal = true;
          orientation.angle = 270;
          break;
        case 8:
          // 90° rotate left
          orientation.angle = 90;
          break;
      }
      observer.next(orientation);
      observer.complete();
    };
    const data: any = {
      exif: {}
    };
    // 256 KiB should contain all EXIF/ICC/IPTC segments:
    const maxMetaDataSize = 262144;
    const noMetaData = !(file && file.size >= 12 && file.type === 'image/jpeg');
    if (noMetaData || !readFile(Blob.prototype.slice.call(file, 0, maxMetaDataSize),
      (e: any) => {
        if (e.target.error) {
          // FileReader error
          logger.warn(e.target.error);
          callback(data);
          return;
        }
        // Note on endianness:
        // Since the marker and length bytes in JPEG files are always
        // stored in big endian order, we can leave the endian parameter
        // of the DataView methods undefined, defaulting to big endian.
        const buffer = e.target.result;
        const dataView = new DataView(buffer);
        let offset = 2;
        const maxOffset = dataView.byteLength - 4;
        let headLength = offset;
        let markerBytes;
        let markerLength;
        // Check for the JPEG marker (0xffd8):
        if (dataView.getUint16(0) === 0xffd8) {
          while (offset < maxOffset) {
            markerBytes = dataView.getUint16(offset);
            // Search for APPn (0xffeN) and COM (0xfffe) markers,
            // which contain application-specific meta-data like
            // Exif, ICC and IPTC data and text comments:
            if ((markerBytes >= 0xffe0 && markerBytes <= 0xffef) ||
              markerBytes === 0xfffe) {
              // The marker bytes (2) are always followed by
              // the length bytes (2), indicating the length of the
              // marker segment, which includes the length bytes,
              // but not the marker bytes, so we add 2:
              markerLength = dataView.getUint16(offset + 2) + 2;
              if (offset + markerLength > dataView.byteLength) {
                logger.warn('Invalid meta data: Invalid segment size.');
                break;
              }

              parseExifData(logger, dataView, offset, markerLength, data);
              offset += markerLength;
              headLength = offset;
            } else {
              // Not an APPn or COM marker, probably safe to
              // assume that this is the end of the meta data
              break;
            }
          }
          // Meta length must be longer than JPEG marker (2)
          // plus APPn marker (2), followed by length bytes (2):
          if (headLength > 6) {
            if (buffer.slice) {
              data.imageHead = buffer.slice(0, headLength);
            } else {
              // Workaround for IE10, which does not yet
              // support ArrayBuffer.slice:
              data.imageHead = new Uint8Array(buffer)
                .subarray(0, headLength);
            }
          }
        } else {
          logger.warn('Invalid JPEG file: Missing JPEG marker.');
        }
        callback(data);
      },
      'readAsArrayBuffer'
    )) {
      callback(data);
    }
  });
}

export interface IImageData {
  image: HTMLImageElement;
  file?: File;
  mimeType: string;
  width: number;
  height: number;
  swapDimensions: boolean;
  isTransparent?: boolean;
  canvas?: HTMLCanvasElement;
  transformation?: IPhotoOrientation;
}

function drawImageToCanvas(imageData: IImageData, canvas: HTMLCanvasElement): CanvasRenderingContext2D {
  const ctx = canvas.getContext('2d');
  let imageWidth = imageData.image.width;
  let imageHeight = imageData.image.height;

  const swapDimensions: boolean = (imageData.transformation && imageData.transformation.angle && (imageData.transformation.angle % 180) !== 0);
  const fitToMaxSize = imageData.mimeType === 'image/svg+xml' || (swapDimensions ? (imageWidth > canvas.height || imageHeight > canvas.width) : (imageWidth > canvas.width || imageHeight > canvas.height));
  if (fitToMaxSize) {
    // stretch vector image to full width/height
    const fitSize = calculateAspectRatioFit(imageWidth, imageHeight, swapDimensions ? canvas.height : canvas.width, swapDimensions ? canvas.width : canvas.height);
    imageWidth = fitSize.width;
    imageHeight = fitSize.height;
  }

  // apply transformation
  if (imageData.transformation) {
    // translate to center-canvas
    // the origin [0,0] is now center-canvas
    ctx.translate(canvas.width / 2, canvas.height / 2);

    if (imageData.transformation.angle) {
      // rotate the canvas by angle
      ctx.rotate(-imageData.transformation.angle * Math.PI / 180);
    }

    // flip if required
    ctx.scale((imageData.swapDimensions ? imageData.transformation.flipVertical : imageData.transformation.flipHorizontal) ? -1 : 1, (imageData.swapDimensions ? imageData.transformation.flipHorizontal : imageData.transformation.flipVertical) ? -1 : 1);

    // draw the signature
    // since images draw from top-left offset the draw by 1/2 width & height
    ctx.drawImage(imageData.image, -imageData.width / 2 + (imageData.width - imageWidth) / 2, -imageData.height / 2 + (imageData.height - imageHeight) / 2, imageWidth, imageHeight);

    if (imageData.transformation.angle) {
      // un-rotate the canvas by angle
      ctx.rotate(imageData.transformation.angle * Math.PI / 180);
    }
    // un-translate the canvas back to origin==top-left canvas
    ctx.translate(-canvas.width / 2, -canvas.height / 2);
  } else {
    ctx.drawImage(imageData.image, 0, 0, imageWidth, imageHeight, (canvas.width - imageData.width) / 2, (canvas.height - imageData.height) / 2, imageData.width, imageData.height);
  }
  return ctx;
}

function containsTransparentPixels(pixelData: Uint8ClampedArray): boolean {
  for (let i = 0; i < pixelData.length; i += 4) {
    if (pixelData[i + 3] === 0) {
      return true;
    }
  }
  return false;
}

export function calculateAspectRatioFit(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number): { width: number, height: number } {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return {width: srcWidth * ratio, height: srcHeight * ratio};
}

export function getImageInfo(file: File, options: { maxWidth: number, maxHeight: number, aspectRatio?: number, transformation?: IPhotoOrientation, createCanvas?: boolean, checkTransparency?: boolean, minWidth?: number }): Observable<IImageData> {
  const fileType = file.type;
  const reader = new FileReader();
  const result = fromEvent(reader, 'load')
    .pipe(
      mergeMap((readerEvent: any) => {
        const image = new Image();
        const loadResult = new Observable((observer: Observer<any>) => {
          image.addEventListener('load', evt => {
            observer.next(evt);
            observer.complete();
          });
          image.addEventListener('error', () => {
            console.log('Img err.');
            observer.error('Error occurred when image is loading...');
            observer.complete();
          });
        });
        image.src = readerEvent.target.result;
        return loadResult;
      }),
      map((evt: any) => {
        const image = evt.srcElement;
        let width = image.width;
        let height = image.height;
        const sizeRatio = image.height / image.width;
        const swapDimensions: boolean = (options.transformation && options.transformation.angle && (options.transformation.angle % 180) !== 0);
        const fitToMaxSize = fileType === 'image/svg+xml' || (swapDimensions ? (width > options.maxHeight || height > options.maxWidth) : (width > options.maxWidth || height > options.maxHeight));
        if (fitToMaxSize) {
          // stretch vector image to full width/height
          const fitSize = calculateAspectRatioFit(swapDimensions ? height : width, swapDimensions ? width : height, swapDimensions ? options.maxHeight : options.maxWidth, swapDimensions ? options.maxWidth : options.maxHeight);
          width = fitSize.width;
          height = fitSize.height;
        }
        const targetImageWidth = Math.floor(width);
        const targetImageHeight = Math.floor(height);
        if (targetImageWidth > image.width) {
          // upscale detected
        }

        if (width < options.minWidth) {
          width = options.minWidth;
        }

        // apply aspectRatio to canvas if available
        if (options.aspectRatio) {
          if (swapDimensions) {
            if (width >= height) {
              height = width * options.aspectRatio;
            } else {
              if (height / width > options.aspectRatio) {
                width = height / options.aspectRatio;
              } else {
                height = width * options.aspectRatio;
              }
            }
          } else {
            if (width >= height) {
              if (width / height > options.aspectRatio) {
                height = width / options.aspectRatio;
              } else {
                width = height * options.aspectRatio;
              }
            } else {
              width = height * options.aspectRatio;
            }
          }
        }

        const maxW = swapDimensions ? options.maxHeight : options.maxWidth;
        const maxH = swapDimensions ? options.maxWidth : options.maxHeight;
        if (width > height) {
          if (width > maxW) {
            width = maxW;
            height = width * sizeRatio;
          }
        } else {
          if (height > maxH) {
            height = maxH;
            width = height / sizeRatio;
          }
        }
        height = Math.floor(height);
        width = Math.floor(width);

        const imageData: IImageData = {
          file,
          image,
          mimeType: fileType,
          width,
          height,
          swapDimensions,
          transformation: options.transformation
        };
        if (options.checkTransparency || options.createCanvas) {
          const canvas = document.createElement('canvas');
          canvas.width = swapDimensions ? height : width;
          canvas.height = swapDimensions ? width : height;
          // draw image to canvas to be able to detect transparent pixels
          const ctx = drawImageToCanvas(imageData, canvas);
          const pixelData = ctx.getImageData((canvas.width - targetImageWidth) / 2, (canvas.height - targetImageHeight) / 2, targetImageWidth, targetImageHeight).data;
          if (options.checkTransparency) {
            // check transparency
            imageData.isTransparent = containsTransparentPixels(pixelData);
          }
          imageData.canvas = canvas;
        }
        return imageData;
      })
    );
  reader.readAsDataURL(file);
  return result;
}

export function resizeImage(file: File, maxWidth: number, maxHeight: number, aspectRatio?: number, transformation?: IPhotoOrientation, minWidth?: number, imageTransformer?: (imageData: IImageData) => Observable<IPhotoBlobData>): Observable<IPhotoBlobData> {
  return getImageInfo(file, {maxWidth, maxHeight, aspectRatio, transformation, checkTransparency: true, minWidth}).pipe(
    mergeMap((imageData: IImageData) => {
      if (imageTransformer) {
        return imageTransformer(imageData);
      } else {
        return getCanvasBlob(imageData.canvas).pipe(
          map(blob => {
            return {
              blob,
              width: imageData.canvas.width,
              height: imageData.canvas.height
            };
          })
        );
      }
    })
  );
}

export function getImageMimeType(img: string | Blob): Observable<string> {
  return new Observable((observer: Observer<string>) => {
    (require as any).ensure([], () => {
      const imageType = require('image-type');
      if (typeof img === 'string') {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', img);
        xhr.responseType = 'arraybuffer';
        xhr.onload = () => {
          const fileType = imageType(new Uint8Array(xhr.response));
          observer.next(fileType ? fileType.mime : null);
          observer.complete();
        };
        xhr.send();
      } else {
        observer.next(imageType(img).mime);
        observer.complete();
      }
    }, 'image-type');
  });
}

const exifTagTypes = {
  // byte, 8-bit unsigned int:
  1: {
    getValue(dataView: DataView, dataOffset: number) {
      return dataView.getUint8(dataOffset);
    },
    size: 1
  },
  // ascii, 8-bit byte:
  2: {
    getValue(dataView: DataView, dataOffset: number) {
      return String.fromCharCode(dataView.getUint8(dataOffset));
    },
    size: 1,
    ascii: true
  },
  // short, 16 bit int:
  3: {
    getValue(dataView: DataView, dataOffset: number, littleEndian: boolean) {
      return dataView.getUint16(dataOffset, littleEndian);
    },
    size: 2
  },
  // long, 32 bit int:
  4: {
    getValue(dataView: DataView, dataOffset: number, littleEndian: boolean) {
      return dataView.getUint32(dataOffset, littleEndian);
    },
    size: 4
  },
  // rational = two long values, first is numerator, second is denominator:
  5: {
    getValue(dataView: DataView, dataOffset: number, littleEndian: boolean) {
      return dataView.getUint32(dataOffset, littleEndian) /
        dataView.getUint32(dataOffset + 4, littleEndian);
    },
    size: 8
  },
  // slong, 32 bit signed int:
  9: {
    getValue(dataView: DataView, dataOffset: number, littleEndian: boolean) {
      return dataView.getInt32(dataOffset, littleEndian);
    },
    size: 4
  },
  // srational, two slongs, first is numerator, second is denominator:
  10: {
    getValue(dataView: DataView, dataOffset: number, littleEndian: boolean) {
      return dataView.getInt32(dataOffset, littleEndian) /
        dataView.getInt32(dataOffset + 4, littleEndian);
    },
    size: 8
  }
};

function getExifValue(logger: Logger, dataView: DataView, tiffOffset: number, offset: number, type: number, length: number, littleEndian: boolean) {
  const tagType = exifTagTypes[type];
  let tagSize;
  let dataOffset;
  let values;
  let i;
  let str;
  let c;
  if (!tagType) {
    logger.warn('Invalid Exif data: Invalid tag type.');
    return;
  }
  tagSize = tagType.size * length;
  // Determine if the value is contained in the dataOffset bytes,
  // or if the value at the dataOffset is a pointer to the actual data:
  dataOffset = tagSize > 4
    ? tiffOffset + dataView.getUint32(offset + 8, littleEndian)
    : (offset + 8);
  if (dataOffset + tagSize > dataView.byteLength) {
    logger.warn('Invalid Exif data: Invalid data offset.');
    return;
  }
  if (length === 1) {
    return tagType.getValue(dataView, dataOffset, littleEndian);
  }
  values = [];
  for (i = 0; i < length; i += 1) {
    values[i] = tagType.getValue(dataView, dataOffset + i * tagType.size, littleEndian);
  }
  if (tagType.ascii) {
    str = '';
    // Concatenate the chars:
    for (i = 0; i < values.length; i += 1) {
      c = values[i];
      // Ignore the terminating NULL byte(s):
      if (c === '\u0000') {
        break;
      }
      str += c;
    }
    return str;
  }
  return values;
}

function parseExifTag(logger: Logger, dataView: DataView, tiffOffset: number, offset: number, littleEndian: boolean, data: any) {
  const tag = dataView.getUint16(offset, littleEndian);
  data.exif[tag] = getExifValue(
    logger,
    dataView,
    tiffOffset,
    offset,
    dataView.getUint16(offset + 2, littleEndian), // tag type
    dataView.getUint32(offset + 4, littleEndian), // tag length
    littleEndian
  );
}

function parseExifTags(logger: Logger, dataView: DataView, tiffOffset: number, dirOffset: number, littleEndian: boolean, data: any) {
  let tagsNumber;
  let dirEndOffset;
  let i;
  if (dirOffset + 6 > dataView.byteLength) {
    logger.warn('Invalid Exif data: Invalid directory offset.');
    return;
  }
  tagsNumber = dataView.getUint16(dirOffset, littleEndian);
  dirEndOffset = dirOffset + 2 + 12 * tagsNumber;
  if (dirEndOffset + 4 > dataView.byteLength) {
    logger.warn('Invalid Exif data: Invalid directory size.');
    return;
  }
  for (i = 0; i < tagsNumber; i += 1) {
    parseExifTag(
      logger,
      dataView,
      tiffOffset,
      dirOffset + 2 + 12 * i, // tag offset
      littleEndian,
      data
    );
  }
  // Return the offset to the next directory:
  return dataView.getUint32(dirEndOffset, littleEndian);
}

function parseExifData(logger: Logger, dataView: any, offset: number, length: number, data: any) {
  const tiffOffset = offset + 10;
  let littleEndian;
  let dirOffset;
  // Check for the ASCII code for "Exif" (0x45786966):
  if (dataView.getUint32(offset + 4) !== 0x45786966) {
    // No Exif data, might be XMP data instead
    return;
  }
  if (tiffOffset + 8 > dataView.byteLength) {
    logger.warn('Invalid Exif data: Invalid segment size.');
    return;
  }
  // Check for the two null bytes:
  if (dataView.getUint16(offset + 8) !== 0x0000) {
    logger.warn('Invalid Exif data: Missing byte alignment offset.');
    return;
  }
  // Check the byte alignment:
  switch (dataView.getUint16(tiffOffset)) {
    case 0x4949:
      littleEndian = true;
      break;
    case 0x4D4D:
      littleEndian = false;
      break;
    default:
      logger.warn('Invalid Exif data: Invalid byte alignment marker.');
      return;
  }
  // Check for the TIFF tag marker (0x002A):
  if (dataView.getUint16(tiffOffset + 2, littleEndian) !== 0x002A) {
    logger.warn('Invalid Exif data: Missing TIFF marker.');
    return;
  }
  // Retrieve the directory offset bytes, usually 0x00000008 or 8 decimal:
  dirOffset = dataView.getUint32(tiffOffset + 4, littleEndian);
  // Create the exif object to store the tags:
  // Parse the tags of the main image directory and retrieve the
  // offset to the next directory, usually the thumbnail directory:
  parseExifTags(logger, dataView, tiffOffset, tiffOffset + dirOffset, littleEndian, data);
}

function readFile(file: File, callback: any, method: string) {
  const fileReader = new FileReader();
  fileReader.onload = fileReader.onerror = callback;
  method = method || 'readAsDataURL';
  if (fileReader[method]) {
    fileReader[method](file);
    return fileReader;
  }
  return false;
}
