import { ANALYZE_FOR_ENTRY_COMPONENTS, InjectionToken } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Route, RouteConfigLoadEnd, RouteConfigLoadStart, Router, Routes, ROUTES } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

const dataRouteLevelKey = 'dataRouteLevel';

function levelRoutes(routes: Routes, level: string): Route[] {
    return routes.map(r => {
        if (r.children) {
            r.children = levelRoutes(r.children, level);
        }
        return r.loadChildren ? levelRoute(r, level) : r;
    });
}

function levelRoute(routeDef: Route, level: string): Route {
    // add level to route data

    // // following code makes replacement done by https://github.com/shlomiassaf/ng-router-loader/blob/master/src/builtin_codegens.ts
    // // workaround - start
    // const moduleParts = (routeDef.loadChildren as string).split('#');
    // const modulePath = moduleParts[0];
    // const moduleClassName = moduleParts[1];
    // const moduleChunkName = modulePath.substring(modulePath.lastIndexOf('/') + 1);
    // routeDef.loadChildren = () => { return new Promise(function (resolve) {
    //         (<any>require).ensure([], function (require: any) {
    //             resolve(require(modulePath)[moduleClassName]);
    //         }, moduleChunkName);
    //     }
    // )};
    // (<any>routeDef).level = level;
    // return routeDef;
    // // // workaround - end

    return {...routeDef, data: {...routeDef.data, [dataRouteLevelKey]: level}};
}

export function lazyModuleLoadingChanged(router: Router, level: string): Observable<boolean> {
    let moduleLoadCount = 0;
    const loadedRoutes: Array<string> = [];
    return router.events.pipe(map((event) => {
            if (event instanceof RouteConfigLoadStart) {
                if (event.route.data[dataRouteLevelKey] === level) {
                    moduleLoadCount++;
                }
            } else if (event instanceof RouteConfigLoadEnd) {
                if (event.route.data[dataRouteLevelKey] === level) {
                    if (loadedRoutes.indexOf(event.route.path) === -1) {
                        loadedRoutes.push(event.route.path);
                    } else {
                        moduleLoadCount--;
                    }
                }
            }
            if (event instanceof NavigationStart) {
               moduleLoadCount = 0;
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
               moduleLoadCount--;
            }
            return moduleLoadCount > 0;
        }),
        distinctUntilChanged()
    );
}

export const ROUTES_LEVEL = new InjectionToken<any>('ROUTES_LEVEL');

export function provideModuleRoutes(routesLevelDef: any) {
    return levelRoutes(routesLevelDef.routes, routesLevelDef.level);
}

export function provideLazyRoutes(providedRoutes: Routes, level: string): any {
    return [
        // ANALYZE_FOR_ENTRY_COMPONENTS registers all components used in routes as module entryComponents (same way as its done in Angular's RouterModule.forRoot/forChild)
        {
            provide: ANALYZE_FOR_ENTRY_COMPONENTS,
            multi: true,
            useValue: providedRoutes
        },
        // store routes and level to the ROUTES_LEVEL injection token to be able to pass it to the provideModuleRoutes factory
        {
            provide: ROUTES_LEVEL,
            useValue: {
                level,
                routes: providedRoutes
            }
        },
        {
            provide: ROUTES,
            multi: true,
            useValue: providedRoutes, // useValue must be defined for AOT static analysis (without it dont compile lazy loaded modules provided by factory)
            useFactory: provideModuleRoutes,
            deps: [ROUTES_LEVEL]
        },
    ];
}
