import { Injectable } from '@angular/core';

import { Config } from './utils/config';

/**
 * The available options to set the Level of the Logger.
 */
export enum Level {
    OFF = 0,
    ERROR = 1,
    WARN = 2,
    INFO = 3,
    DEBUG = 4,
    LOG = 5
}

@Injectable({ providedIn: 'root' })
export class Logger {
    level: Level;

    constructor() {
        const configLevel = Config.get<string>('logLevel');
        this.level = configLevel ? Level[configLevel] : Level.OFF;
    }

    log(message: string) {
        this.logWithLevel(message, Level.LOG);
    }

    debug(message: string) {
        this.logWithLevel(message, Level.DEBUG);
    }

    info(message: string) {
        this.logWithLevel(message, Level.INFO);
    }

    warn(message: string) {
        this.logWithLevel(message, Level.WARN);
    }

    error(message: string) {
        this.logWithLevel(message, Level.ERROR);
    }

    logWithLevel(message: string, level: Level) {
        if (level <= this.level) {
            console.log(message);
        }
    }
}
