import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { forkJoin, Observable, of, isObservable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({providedIn: 'root'})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  private _deactivationHandlers: Array<() => Observable<boolean>> = [
    () => of(true)
  ];

  static checkMultipleComponentsDeactivation(components: Array<CanComponentDeactivate>): Observable<boolean> {
    const results = components.map(c => {
      const canComponentDeactivate = c.canDeactivate();
      return canComponentDeactivate instanceof Observable
        ? canComponentDeactivate
        : of(canComponentDeactivate);
    });
    return forkJoin(results).pipe(
      map((res: Array<boolean>) => res.every(r => r))
    );
  }

  constructor(private _router: Router, private _location: Location) {
  }

  canDeactivate(component: CanComponentDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Observable<boolean> | boolean {
    return forkJoin(this._deactivationHandlers.map(h => h()).map(hRes => isObservable(hRes) ? hRes : of(hRes))).pipe(
      mergeMap(handlersResults => {
        const allowedByHandlers = !handlersResults.some(allowed => !allowed);
        if (allowedByHandlers) {
          const canComponentDeactivate: boolean | Observable<boolean> = component && component.canDeactivate ? component.canDeactivate() : true;
          if (isObservable(canComponentDeactivate)) {
            return canComponentDeactivate;
          } else {
            return of(canComponentDeactivate);
          }
        } else {
          return of(false);
        }
      }),
      map((canDeactivate: boolean) => {
        if (!canDeactivate) {
          // workaround for https://github.com/angular/angular/issues/13586
          if (this._router.getCurrentNavigation().trigger === 'popstate') {
            this._location.go(currentState.url);
          }
        }
        return canDeactivate;
      })
    );
  }

  registerDeactivationHandler(handler: () => Observable<boolean>) { // used by DialogManager to block back navigation when some dialog is opened
    this._deactivationHandlers.push(handler);
  }
}
